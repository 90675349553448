import React from "react"

const SubHeading = props => (
  <span
    className="bg-primary text-light p-1 me-1 fw-bold shadow"
    style={{
      borderRadius: "4px",
    }}
  >
    {props.children}
  </span>
)

export default SubHeading

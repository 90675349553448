import React from "react"

const Title = props => (
  <h1
    className="card-body text-center fat-text shadow"
    style={{
      backgroundColor: "#" + Math.random().toString(16).slice(-6) + "50",
    }}
  >
    {props.children}
  </h1>
)

export default Title

import React from "react"

const Numbered = props => (
  <h1
    className="text-center bg-primary p-3 fw-bold shadow"
    style={{
      borderRadius: "4px",
    }}
  >
    {props.children}
  </h1>
)

export default Numbered

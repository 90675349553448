import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import Numbered from "../components/Headings/Numbered"
import Heading from "../components/Headings/Heading"
import SubHeading from "../components/Headings/SubHeading"
import Sidebar from "../components/Sidebar/Sidebar"
import {
  FaArrowLeft,
  FaBookReader,
  FaBriefcase,
  FaCameraRetro,
  FaIdCard,
  FaServer,
  FaThLarge,
} from "react-icons/fa"
import styled from "styled-components"
import Accordion from "../components/Sidebar/Accordion"
import parseCategoryData from "../utils/parseCategoryData"
import Title from "../components/Headings/Title"

const Wrap = styled.div`
  max-height: 77vh;
  display: flex;
`

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`

const WDPage = ({ data }) => {
  const subjectCategories = parseCategoryData(data.allMdx.edges)
  const [ToggleSidebar, setToggleSidebar] = useState(true)
  const toggle = () => setToggleSidebar(!ToggleSidebar)

  return (
    <Layout>
      <Seo title="Web Development" />
      <section className="de-section">
        <Title>Web Development</Title>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            {ToggleSidebar && (
              <div
                className="col-lg-3 mb-4"
                id="sticky-sidebar"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div
                  className="card shadow border-primary sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Chapters</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Accordion allowMultipleOpen>
                        {Object.keys(subjectCategories).map((category, i) => {
                          return (
                            <div key={i} label={category} isOpen>
                              <Sidebar
                                subjectLink={`web_development`}
                                category={subjectCategories[category]}
                                target="ToggleSidebar"
                              />
                            </div>
                          )
                        })}
                      </Accordion>
                    </InnerScroll>
                  </Wrap>
                </div>
              </div>
            )}

            <div className={ToggleSidebar ? "col-lg-9 mb-4" : "col-lg-12 mb-4"}>
              <div
                className="card border-primary shadow"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-header">
                  <button
                    onClick={toggle}
                    id="ToggleSidebar"
                    className="btn btn-outline-primary btn-sm"
                  >
                    <FaArrowLeft /> Toggle Sidebar
                  </button>
                </div>

                <div className="card-body">
                  <Heading>What is Web Development ?</Heading>
                  <p>
                    Web development refers to the process of designing and
                    creating websites and web applications that are accessible
                    over the internet. Web development encompasses a wide range
                    of tasks, including web design, front-end development,
                    back-end development, and web content management.
                    <br />
                    <br />
                    Web design involves the creation of the visual appearance
                    and layout of a website, including the use of color schemes,
                    typography, and graphic elements. Front-end development
                    involves the creation of the user interface of a website
                    using HTML, CSS, and JavaScript. Back-end development
                    involves the creation of the server-side logic and database
                    integration that powers the website. Web content management
                    involves the creation and management of the content
                    displayed on the website, such as text, images, and
                    multimedia.
                    <br />
                    <br />
                    Web developers use a variety of tools and technologies to
                    build websites and web applications, including web
                    development frameworks, content management systems, and web
                    hosting services. Some of the popular web development
                    frameworks include Angular, React, and Vue.js. Content
                    management systems such as WordPress and Drupal provide a
                    user-friendly interface for managing website content. Web
                    hosting services provide the infrastructure needed to host a
                    website on the internet.
                    <br />
                    <br />
                    Web development is an essential aspect of modern business
                    and society, with websites and web applications serving as
                    critical communication and commerce channels. Web developers
                    play a crucial role in creating and maintaining these
                    digital platforms, ensuring that they are accessible,
                    reliable, and secure.
                    <br />
                    <br />
                    There are several types of website development, each serving
                    different purposes and audiences. Here are some of the most
                    common types:
                    <br />
                    <br />
                    <SubHeading>Static Website Development</SubHeading> Static
                    websites are built using HTML and CSS and do not require any
                    server-side processing or database integration. Static
                    websites are ideal for small businesses or individuals who
                    want to create a simple online presence to showcase their
                    services, products or portfolio.
                    <br />
                    <br />
                    <SubHeading>Dynamic Website Development</SubHeading> Dynamic
                    websites display content that is generated on the
                    server-side in response to user requests. These websites are
                    built using programming languages such as PHP, Ruby on
                    Rails, or Node.js and often involve database integration.
                    Dynamic websites are suitable for e-commerce, social media
                    platforms, online banking, and other sites that require
                    frequent content updates, user authentication, and
                    personalized content delivery.
                    <br />
                    <br />
                    <SubHeading>E-commerce Website Development</SubHeading>{" "}
                    E-commerce websites are built for online businesses that
                    want to sell their products or services online. E-commerce
                    websites include features such as shopping carts, payment
                    gateways, and inventory management systems. Popular
                    e-commerce platforms include Shopify, WooCommerce, and
                    Magento.
                    <br />
                    <br />
                    <SubHeading>
                      Content Management System (CMS) Website Development
                    </SubHeading>{" "}
                    CMS websites provide a user-friendly interface for managing
                    website content. CMS platforms such as WordPress, Drupal,
                    and Joomla allow website owners to create, edit, and publish
                    content without requiring programming skills.
                    <br />
                    <br />
                    <SubHeading>Web Application Development</SubHeading> Web
                    applications are interactive software programs that run on
                    web browsers. Web applications can be used for a variety of
                    purposes, such as social networking, file sharing, project
                    management, and more. Web applications are built using
                    programming languages such as JavaScript, Ruby, and Python.
                    <br />
                    <br />
                    <SubHeading>Responsive Website Development</SubHeading> With
                    the increasing use of mobile devices to access the internet,
                    responsive website development has become essential.
                    Responsive websites are designed to adapt to different
                    screen sizes and devices, providing an optimal user
                    experience on any device.
                    <br />
                  </p>
                  <Heading>
                    What is the difference between Static Web Development and
                    Dynamic Web Development ?
                  </Heading>
                  The main difference between static and dynamic web development
                  lies in how the content of the website is generated and
                  displayed to users.
                  <br />
                  <br />
                  <p>
                    <SubHeading>Static web development</SubHeading> refers to
                    the creation of websites that contain fixed content that
                    does not change unless the website is manually updated.
                    Static websites are typically built using HTML and CSS and
                    do not require any server-side processing or database
                    integration. Examples of static websites include online
                    brochures, product catalogs, and personal blogs.
                    <br />
                    <br />
                    <SubHeading>Dynamic web development</SubHeading> on the
                    other hand, refers to the creation of websites that display
                    content that is generated on the server-side in response to
                    user requests. Dynamic websites are built using programming
                    languages such as PHP, Ruby on Rails, or Node.js, and often
                    involve database integration. Dynamic websites can display
                    different content to different users based on their
                    preferences, location, or other factors. Examples of dynamic
                    websites include social media platforms, e-commerce
                    websites, and online banking portals.
                    <br />
                    <br />
                    One of the main advantages of static web development is that
                    it is simple and easy to create, requiring only basic HTML
                    and CSS skills. Static websites are also faster to load and
                    less vulnerable to security breaches compared to dynamic
                    websites. However, static websites are not suitable for
                    websites that require frequent content updates or user
                    interaction.
                    <br />
                    <br />
                    Dynamic web development, on the other hand, offers more
                    flexibility and functionality, making it ideal for websites
                    that require frequent content updates, user authentication,
                    and personalized content delivery. However, dynamic websites
                    require more advanced programming skills and are more
                    complex to create and maintain. Dynamic websites can also be
                    slower to load and require more server resources compared to
                    static websites.
                    <br />
                  </p>
                  <Heading>Why you should learn Web development ?</Heading>
                  <div className="row">
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaBriefcase />
                        </div>
                        <h4>In-demand skill</h4>
                        <p>
                          Web development is a highly in-demand skill in today's
                          job market, with many job opportunities available in a
                          variety of industries. Learning web development can
                          increase your employability and earning potential.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaServer />
                        </div>
                        <h4>Entrepreneurship</h4>
                        <p>
                          Web development skills can also be useful if you want
                          to start your own business or freelance career. Many
                          businesses require a website, and being able to build
                          one yourself can save you money and time.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaCameraRetro />
                        </div>
                        <h4>Creativity</h4>
                        <p>
                          Web development can also be a creative outlet,
                          allowing you to design and build unique websites that
                          showcase your skills and personality.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaIdCard />
                        </div>
                        <h4>Personal Growth</h4>
                        <p>
                          Learning web development can be a challenging and
                          rewarding experience, helping you develop
                          problem-solving skills, attention to detail, and
                          persistence.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaThLarge />
                        </div>
                        <h4>Flexibility</h4>
                        <p>
                          Web development can be done remotely, allowing you to
                          work from anywhere with an internet connection. This
                          can give you more flexibility in your work and
                          personal life.
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 text-center mb-4">
                      <div
                        className="card p-4 border-primary shadow"
                        style={{
                          backgroundColor:
                            "#" + Math.random().toString(16).slice(-6) + "10",
                        }}
                      >
                        <div
                          className="text-primary mb-2"
                          style={{ fontSize: "60px" }}
                        >
                          <FaBookReader />
                        </div>
                        <h4>Continuous Learning</h4>
                        <p>
                          Learning web development can be a lifelong pursuit,
                          keeping you engaged and up-to-date with the latest
                          trends and developments in the industry.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <Heading>Who should learn Web development ?</Heading>
                  <p>
                    Web development is a valuable skill for anyone who wants to
                    create websites or web applications, regardless of their
                    background or profession. Here are some groups of people who
                    may benefit from learning web development:
                    <br />
                    <br />
                    <SubHeading>Designers</SubHeading> Web development skills
                    can also be valuable for designers who want to create
                    responsive and interactive websites. Knowing how to code can
                    help designers understand the technical limitations and
                    possibilities of their designs, enabling them to create
                    better user experiences.
                    <br />
                    <br />
                    <SubHeading>Entrepreneurs</SubHeading> If you are starting
                    your own business, having web development skills can be
                    useful for building and maintaining your own website or web
                    application. This can save you money and give you more
                    control over your online presence.
                    <br />
                    <br />
                    <SubHeading>Marketers</SubHeading> Web development skills
                    can also be useful for marketers who want to create landing
                    pages, email templates, or other web-based marketing
                    materials. Knowing how to code can help marketers customize
                    their campaigns and track user behavior more effectively.
                    <br />
                    <br />
                    <SubHeading>Anyone interested in technology</SubHeading> Web
                    development is a fascinating and constantly evolving field,
                    and learning web development can be a rewarding experience
                    for anyone interested in technology and innovation.
                    <br />
                  </p>
                  <Heading>Learning Objectives</Heading>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>1</Numbered>
                        <h5 className="m-2">
                          Understanding web development basics: This involves
                          learning the basics of HTML, CSS, and JavaScript,
                          which are the building blocks of web development. It
                          also includes learning about web design principles,
                          web standards, and accessibility.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>2</Numbered>
                        <h5 className="m-2">
                          Building web pages and websites: This involves
                          learning how to create static and dynamic web pages,
                          and how to design and build websites from scratch. It
                          also includes learning about web hosting, domain
                          names, and content management systems.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>3</Numbered>
                        <h5 className="m-2">
                          Creating interactive web applications: This involves
                          learning how to use JavaScript and other programming
                          languages to create interactive web applications, such
                          as online forms, games, and user interfaces.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>4</Numbered>
                        <h5 className="m-2">
                          Developing web-based software: This involves learning
                          how to use web development frameworks, such as React,
                          Angular, or Vue, to develop web-based software
                          applications. It also includes learning about
                          server-side programming languages, such as PHP, Ruby,
                          or Python, and database management.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>5</Numbered>
                        <h5 className="m-2">
                          Optimizing web performance: This involves learning how
                          to optimize web page loading times, minimize page
                          weight, and improve website speed and performance. It
                          also includes learning about website security, SEO,
                          and mobile optimization.
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div
                      className="card shadow rounded list-group-item border-primary mb-2"
                      style={{
                        backgroundColor:
                          "#" + Math.random().toString(16).slice(-6) + "10",
                      }}
                    >
                      <div className="d-flex w-100 justify-content-between align-items-center m-2">
                        <Numbered>6</Numbered>
                        <h5 className="m-2">
                          Collaborating with other developers: This involves
                          learning how to work effectively with other
                          developers, using version control systems, such as
                          Git, and collaborating on code bases and projects.
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WDPage

export const pageQuery = graphql`
  query WDPage {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/(web_development)/.*.(mdx)/" } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            topLevelDir
            subDir
          }
        }
      }
    }
  }
`
